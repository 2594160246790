const scrollingevent = new CustomEvent('scrollingevent');
const scrolltopevent = new CustomEvent('scrolltopevent');

jQuery(function() {
    initScrollEvent();
    initListeners();

    prestashop.on('responsive update', function(e) {
        console.log('on responsive update');
        console.log(e.mobile);
        if (e.mobile) {
            if ($('#footer .mdg-block-reassurance ul').length > 0) {
                $('#footer .mdg-block-reassurance ul').slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    speed: 800,
                    dots: false,
                    arrows: false,
                    infinite: true,
                    autoplay: true
                });
            }
        } else {
            if ($('#footer .mdg-block-reassurance ul').length > 0) {
                $('#footer .mdg-block-reassurance ul').slick('unslick');
            }
        }
    });
});

function initScrollEvent()
{
    $(window).on('scroll', function (event) {
        var scroll          = $(window).scrollTop();
        var headerHeight    = $('#header').outerHeight();
        if(scroll > headerHeight) {
            document.dispatchEvent(scrollingevent);
            $('body').addClass('scrolling');
        } else {
            document.dispatchEvent(scrolltopevent);
            $('body').removeClass('scrolling');
        }
    });
}

function initListeners()
{
    $(document).on('click', '.back-to-top', function (e) {
        e.preventDefault();
        $('body,html').animate({
          scrollTop: 0
        }, 600);
        return;
    });

    $(document).on('click', '.go-to-anchor[href]', function(e) {
        e.preventDefault();
        var selector = $(this).attr('href');
        if ($(selector).length > 0) {
            $('body,html').animate({
                scrollTop: $(selector).position().top
            }, 600);
        }
        return;
    });
}
